import React, { Component } from "react";
import { LinearProgress } from '@material-ui/core';
import Title from "../atoms/title"
import Trustpilot from '../molecules/trustpilot'


class Loader extends Component {
    constructor(props){
      super(props);
      this.state = {
        title: this.props.title,
        trustContent: this.props.trustContent,
        classNames: this.props.classNames,
        onComplete: this.props.onComplete,
        loaderProgress: 0,
        isComplete: false,
        isLinearLoader: this.props.isLinear,
        isCircularLoader: this.props.isCircular,
        isTrustLoader: this.props.isTrust,
        isTypingLoader: this.props.isTyping,
        isMuiLoader: this.props.isMui,
        forever: this.props.forever
      }
      this.activateLoader = this.activateLoader.bind(this);
      this.updateParent = this.updateParent.bind(this);

      if(this.state.isLinearLoader) this.activateLoader();
      if(this.state.isMuiLoader) this.activateLoader();
      if(this.state.isTypingLoader) this.activateTypingLoader();

    }

    activateLoader() {
        // let durationStart1 = (this.state.isTrustLoader) ? 5200 : 1200;
        let durationStart2 = (this.state.isTrustLoader) ? 1500 : 500;
        let durationStart3 = (this.state.isTrustLoader) ? 3000 : 1000;
        let durationEnd = (this.state.isTrustLoader) ? 5800 : 1500;
        setTimeout(() => {
            this.setState({
                loaderProgress: 10
            });
        }, 100);
        setTimeout(() => {
            this.setState({
                loaderProgress: 50
            });
        }, durationStart2);
        setTimeout(() => {
            this.setState({
                loaderProgress: (this.state.isTrustLoader) ? 75 : 100
            });
        }, durationStart3);
        if(this.state.isTrustLoader) {
            setTimeout(() => {
                this.setState({
                    loaderProgress: 100
                });
            }, 5400);
        }
        setTimeout(() => {
            this.setState({
                isComplete: !this.state.isComplete
            });
            this.updateParent();
        }, durationEnd);
    }

    progress(amt) {
        this.setState({
          loaderProgress: amt
        });
    }

    activateMuiLoader() {
        let speed = (this.state.isTrustLoader) ? 28 : 70;
        const timer = setInterval(() => {
            if (this.state.loaderProgress === 100) {
                this.setState({
                    isComplete: !this.state.isComplete
                });
                this.updateParent();
                return clearInterval(timer);
            }
            const diff = Math.random() * speed;
            return this.progress(Math.min(this.state.loaderProgress + diff, 100));
        }, 500);
    }

    activateTypingLoader() {
        let duration = (this.state.forever) ? 30000 : 4000;
        setTimeout(() => {
            this.setState({
                isComplete: !this.state.isComplete
            });
            this.updateParent();
        }, duration);
    }

    updateParent() {
        this.state.onComplete(this.state.isComplete);
    }

    render() {
        const createClassList = () => {
            let classList = 'cm-loader';
            if(this.state.classNames) classList = classList + ' ' + this.state.classNames;
            if(this.state.isTrustLoader) classList = classList + ' cm-loader--trust' ;
            if(this.state.isTypingLoader) classList = classList + ' cm-loader--typing' ;
            if(this.state.isMuiLoader) classList = classList + ' cm-loader--mui' ;
            return classList;
        }
    
        const titleComponent = (this.state.title) ? (
            <div className='cm-loader__title'>
                <Title
                    type='h3'
                    title={this.state.title}
                    align='center'
                    color='dark'
                />
            </div>
        ) : null;

        const trustComponent = (this.state.isTrustLoader) ? (
            <div className='cm-loader__trust'>
                <Trustpilot
                    content={this.state.trustContent}
                />
            </div>
        ) : null;
    
        const linearLoaderInlineStyle = {
            width: this.state.loaderProgress + '%',
        };

        const linearLoaderComponent = (this.state.isLinearLoader) ? (
            <div className='cm-loader__progress-bar'>
                <div className='cm-loader__progress-bar-fill' style={linearLoaderInlineStyle}></div>
            </div>
        ) : null;

        const circularLoaderComponent = (this.state.isCircularLoader) ? (
            <div className='cm-loader__circular'>
                <div className="cm-loader__rings"><div></div><div></div><div></div><div></div></div>
            </div>
        ) : null;

        const typingLoaderComponent = (this.state.isTypingLoader) ? (
            <div className="cm-loader__typing">
                <span></span>
                <span></span>
                <span></span>
            </div>
        ): null;

        const muiLoaderComponent = (this.state.isMuiLoader) ? (
            <div className="cm-loader__mui">
                <LinearProgress variant="determinate" value={this.state.loaderProgress} />
            </div>
        ) : null;

        return (
            <div className={createClassList()}>
                {titleComponent}
                {trustComponent}
                {linearLoaderComponent}
                {circularLoaderComponent}
                {typingLoaderComponent}
                {muiLoaderComponent}
            </div>
        );
    }
}


export default Loader;

{/* <Loader 
    title="Lorem Ipsum"
    classNames="extra__class-name--goes-here"
/> */}